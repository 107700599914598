<template>
  <div class="p-3">
    <ShareDialog
      :visibleShareDialog="visibleShareDialog"
      :practice="practice"
      @toggleVisibleShareDialog="toggleVisibleShareDialog"
    />
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-row>
      <el-col :span="4">
        <span style="opacity: 0;">1</span>
      </el-col>
      <el-col style="text-align: center" :span="16">
        <h1>
          {{ `Result: ${practice.exam.title}` }}
          <router-link
            v-if="isRoleTeacher() || isRoleAdmin()"
            :to="{
              name: 'EditPractices',
              params: { practiceId: $route.params.practiceId }
            }"
          >
            <i class="fas fa-edit action-icon" />
          </router-link>
        </h1>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-button
          type="primary"
          @click="
            () => {
              toggleVisibleShareDialog(true);
            }
          "
        >
          <i class="fas fa-lock-open" />
          {{ $t("practice.form.share") }}
        </el-button>
      </el-col>
    </el-row>
    <el-tabs
      v-model="activeTab"
      type="card"
      @tab-click="handleTabChange"
      v-if="true"
    >
      <el-tab-pane
        :key="tab.id"
        v-for="tab in tabs"
        :label="tab.title"
        :name="String(tab.id)"
      >
        <div v-if="activeTab === 'all' && (isRoleAdmin() || isRoleTeacher())">
          <el-table :data="[{ title: 'Percent' }]">
            <el-table-column fixed label="#" prop="title" width="120px" />
            <el-table-column
              align="center"
              :key="question.id"
              v-for="question in practice.exam.questions"
              :label="String(question.order)"
            >
              <div
                :style="
                  question.correct_percent !== 100
                    ? 'color: #fff; background: red; text-align: center'
                    : 'color: #3c763d; text-align: center'
                "
              >
                <b>{{ `${question.correct_percent}%` }}</b>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <Results
          v-if="isRoleAdmin() || isRoleTeacher()"
          :id="$route.params.practiceId"
          :results="results"
          :practice="practice"
          :enabledDelete="isRoleTeacher() || isRoleAdmin()"
          @deletePracticeUserExam="deletePracticeUserExam"
        />
        <template v-if="isRoleAdmin() || isRoleTeacher()">
          <hr class="separate-line" />
          <h2>題目詳情</h2>
          <el-row>
            <el-table :data="[{ title: 'Percent' }]" style="width: 100%">
              <el-table-column fixed label="#" prop="title" />
              <el-table-column
                align="center"
                :key="question.id"
                v-for="question in practice.exam.questions"
              >
                <template slot="header">
                  <div
                    :class="
                      currentQuestion &&
                      currentQuestion.order === question.order
                        ? 'orderTextActiveText'
                        : 'orderText'
                    "
                  >
                    {{ question.order }}
                  </div>
                </template>
                <div
                  style="cursor: pointer;"
                  @click="
                    () => {
                      currentQuestion = question;
                    }
                  "
                >
                  <div
                    :style="
                      question.correct_percent !== 100
                        ? 'color: #fff; background: red; text-align: center'
                        : 'color: #3c763d; text-align: center'
                    "
                  >
                    <b>{{ `${question.correct_percent}%` }}</b>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </el-row>
          <template v-if="practice.template_type === 'DigitalSAT'">
            <MultipleChoiceWithPassage
              v-if="currentQuestion"
              mode="explanation"
              :passageContent="currentQuestion.question.passage.content"
              :questionOrder="currentQuestion.order"
              :content="currentQuestion.question.content"
              :options="currentQuestion.question.options"
              :correctAnswer="currentQuestion.question.answers ? currentQuestion.question.answers[0].answers[0] : '/'"
              :isCorrect="true"
              :explanation="currentQuestion.question.exp"
              :showMark="false"
              :showLine="false"
            ></MultipleChoiceWithPassage>
          </template>
          <template v-else>
            <el-row v-if="currentQuestion" type="flex" justify="space-between">
              <el-col class="border" :span="12">
                <ViewPassage
                  :key="currentQuestion"
                  :passage="currentQuestion.question.passage"
                  :type="currentQuestion.question_type"
                  :section="currentQuestion.question.subject.name"
                />
              </el-col>
              <el-col class="border" :span="12">
                <Question
                  :testType="currentQuestion.question_type"
                  :question="currentQuestion.question"
                  :disabledAction="true"
                  :disableDelete="true"
                  :displayCorrectAnswer="
                    practice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                      isRoleTeacher() ||
                      isRoleAdmin()
                  "
                />
              </el-col>
            </el-row>
          </template>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ShareDialog from "@/components/practices/ShareDialog";
import Results from "@/components/practices/Results";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import Question from "@/components/tests/Question.vue";
import practiceApi from "@/apis/practices";
import Practice from "@/views/practices/Practice";
import roleMixin from "@/mixins/role";
import questionAdapter from "@/components/practices/QuestionAdapter";
import { generatorWebSocket } from "@/class/webSocket";
import $ from "jquery";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} Results - Ivy-Way Academy`
    };
  },
  mixins: [roleMixin],
  components: { Breadcrumb, ShareDialog, ViewPassage, Question, Results },
  data() {
    return {
      results: [],
      questions: [],
      practice: new Practice(),
      currentQuestion: null,
      visibleShareDialog: false,
      activeTab: "all",
      echo: null,
      currentChannel: ""
    };
  },
  watch: {
    currentQuestion() {
      if(this.practice.template_type === "DigitalSAT"){
        this.$nextTick(() => {
          this.getOnlinePassage();
        });
      }
    }
  },
  computed: {
    pageTitle() {
      return this.practice ? `${this.practice.exam.title} Result` : "";
    },
    tabs() {
      if (this.isRoleAdmin()) {
        return [
          { id: "all", title: "All" },
          ...this.practice.getTakeSessionClass()
        ];
      } else if (this.isRoleTeacher()) {
        return [{ id: "all", title: "All" }, ...this.getClassOfTeacher()];
      } else {
        return [{ id: "all", title: "All" }];
      }
    }
  },
  async created() {
    this.echo = generatorWebSocket(localStorage.id_token);
    this.fetchPracticeResults();
    this.connectPracticeResultsWS();
  },
  methods: {
    getOnlinePassage() {
      let vm = this;
      let order = this.currentQuestion.question.order;
      let underLine = $("u");
      let orderObj = {};
      $.each(underLine, function(key, val) {
        let underOrder = 0;
        if (
          $(val)
            .prev(".satQuestionNumber")
            .text() !== ""
        ) {
          underOrder = $(val)
            .prev(".satQuestionNumber")
            .text();
        } else {
          underOrder = $(val)
            .parent("p")
            .prev()
            .find(".satQuestionNumber:last")
            .text();
        }
        underOrder = parseInt(underOrder);
        if (orderObj[`order${underOrder}`]) {
          orderObj[`order${underOrder}`] += $(val).text();
          if (order == underOrder) {
            $(val).text("");
          }
        } else {
          orderObj[`order${underOrder}`] = $(val).text();
          if (order == underOrder) {
            $(val).text("______");
          }
        }
      });
      vm.currentQuestion.question.options[0].title =
        orderObj[`order${order}`];
      vm.currentQuestion.question.tags.forEach((tag, index) => {
        if (tag.id === 3) {
          vm.currentQuestion.question.content =
            "Which choice most logically completes the text?";
        } else if (tag.id === 6) {
          vm.currentQuestion.question.content =
            "Which choice completes the text with the most logical transition?";
        } else if (tag.id === 6) {
          vm.currentQuestion.question.content =
            "Which choice completes the text with the most logical transition?";
        }
      });
      let p = $("#passageContent").find("p");
      $.each(p, function(key, val) {
        $(val).replaceWith(`<span>${$(val).html()}</span>`);
      });
      let u = $("#passageContent").find("u");
      $.each(u, function(key, val) {
        $(val).replaceWith(`<span>${$(val).html()}</span>`);
      });
      $("br").hide();
      const searchRegExp = /&nbsp; &nbsp;/g;
      const replaceWith = "</p><p>";
      const result = $("#passageContent")
        .html()
        .replace(searchRegExp, replaceWith);
      $("#passageContent").html(result);
      let satQuestionNumber = $(".satQuestionNumber");
      $.each(satQuestionNumber, function(key, val) {
        if (order == parseInt($(val).text())) {
          $(val)
            .closest("p")
            .addClass("showP");
        }
      });
      $("#passageContent")
        .find("p")
        .hide();
      $(".showP").show();
      $(".satQuestionNumber").hide();
    },
    getClassOfTeacher() {
      return this.practice
        .getTakeSessionClass()
        .filter(
          ({ teacher_user_id }) =>
            this.isRoleAdmin() ||
            teacher_user_id ===
              Number(this.$store.getters["user/getProfile"].id)
        );
    },
    handleTabChange({ name }) {
      this.results = [];

      if (name !== "all") {
        this.fetchPracticeResults(name);
        this.connectPracticeResultsWS(name);
      } else {
        this.fetchPracticeResults();
        this.connectPracticeResultsWS();
      }
    },
    getExamQuestionCorrectPercent(questionId) {
      return this.questions.find(questions => questions.id === questionId)
        .correct_percent;
    },
    async fetchPracticeResults(classId) {
      const practiceResults = await practiceApi.fetchPracticeResults(
        this.$route.params.practiceId,
        classId
      );

      this.updateResultsState(classId, practiceResults);
    },
    async connectPracticeResultsWS(classId) {
      this.echo.leaveChannel(this.currentChannel);
      if (classId) {
        this.currentChannel = `practice.${this.practice.id}.results.session_class.${classId}`;
      } else {
        this.currentChannel = `practice.${this.practice.id}.results`;
      }

      this.echo.private(this.currentChannel).listen(".answer", data => {
        this.updateResultsState(classId, data);
      });
    },
    updateResultsState(classId, { user_exams, questions, practice }) {
      this.questions = questions;
      if (classId || this.isRoleAdmin() || this.isRoleTeacher()) {
        this.results = user_exams;
      } else if (this.isRoleStudent()) {
        this.results = user_exams.filter(
          ({ user_id }) => user_id === this.$store.getters["user/getProfile"].id
        );
      }
      this.practice = new Practice({
        ...practice,
        exam: {
          ...practice.exam,
          questions: practice.exam.questions.map(question => ({
            ...questionAdapter(question),
            correct_percent: this.getExamQuestionCorrectPercent(question.id)
          }))
        }
      });
    },
    async deletePracticeUserExam(userExamId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await practiceApi.deletePracticeUserExam(userExamId);
        await this.fetchPracticeResults(this.activeTab);
        this.$message.success(this.$t("message.delete_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    toggleVisibleShareDialog(type) {
      this.visibleShareDialog = type;
    }
  }
};
</script>

<style>
.orderText {
  width: 100%;
  height: 100%;
  color: #3c763d;
  text-align: center;
}

.orderTextActiveText {
  width: 100%;
  height: 100%;
  background: #3c763d;
  color: #ffffff;
  text-align: center;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
  max-height: 600px;
}
</style>
