var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3"},[_c('ShareDialog',{attrs:{"visibleShareDialog":_vm.visibleShareDialog,"practice":_vm.practice},on:{"toggleVisibleShareDialog":_vm.toggleVisibleShareDialog}}),_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('span',{staticStyle:{"opacity":"0"}},[_vm._v("1")])]),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":16}},[_c('h1',[_vm._v(" "+_vm._s(`Result: ${_vm.practice.exam.title}`)+" "),(_vm.isRoleTeacher() || _vm.isRoleAdmin())?_c('router-link',{attrs:{"to":{
            name: 'EditPractices',
            params: { practiceId: _vm.$route.params.practiceId }
          }}},[_c('i',{staticClass:"fas fa-edit action-icon"})]):_vm._e()],1)]),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":4}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
            _vm.toggleVisibleShareDialog(true);
          }}},[_c('i',{staticClass:"fas fa-lock-open"}),_vm._v(" "+_vm._s(_vm.$t("practice.form.share"))+" ")])],1)],1),(true)?_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.handleTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('el-tab-pane',{key:tab.id,attrs:{"label":tab.title,"name":String(tab.id)}},[(_vm.activeTab === 'all' && (_vm.isRoleAdmin() || _vm.isRoleTeacher()))?_c('div',[_c('el-table',{attrs:{"data":[{ title: 'Percent' }]}},[_c('el-table-column',{attrs:{"fixed":"","label":"#","prop":"title","width":"120px"}}),_vm._l((_vm.practice.exam.questions),function(question){return _c('el-table-column',{key:question.id,attrs:{"align":"center","label":String(question.order)}},[_c('div',{style:(question.correct_percent !== 100
                  ? 'color: #fff; background: red; text-align: center'
                  : 'color: #3c763d; text-align: center')},[_c('b',[_vm._v(_vm._s(`${question.correct_percent}%`))])])])})],2)],1):_vm._e(),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('Results',{attrs:{"id":_vm.$route.params.practiceId,"results":_vm.results,"practice":_vm.practice,"enabledDelete":_vm.isRoleTeacher() || _vm.isRoleAdmin()},on:{"deletePracticeUserExam":_vm.deletePracticeUserExam}}):_vm._e(),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?[_c('hr',{staticClass:"separate-line"}),_c('h2',[_vm._v("題目詳情")]),_c('el-row',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":[{ title: 'Percent' }]}},[_c('el-table-column',{attrs:{"fixed":"","label":"#","prop":"title"}}),_vm._l((_vm.practice.exam.questions),function(question){return _c('el-table-column',{key:question.id,attrs:{"align":"center"}},[_c('template',{slot:"header"},[_c('div',{class:_vm.currentQuestion &&
                    _vm.currentQuestion.order === question.order
                      ? 'orderTextActiveText'
                      : 'orderText'},[_vm._v(" "+_vm._s(question.order)+" ")])]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
                    _vm.currentQuestion = question;
                  }}},[_c('div',{style:(question.correct_percent !== 100
                      ? 'color: #fff; background: red; text-align: center'
                      : 'color: #3c763d; text-align: center')},[_c('b',[_vm._v(_vm._s(`${question.correct_percent}%`))])])])],2)})],2)],1),(_vm.practice.template_type === 'DigitalSAT')?[(_vm.currentQuestion)?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageContent":_vm.currentQuestion.question.passage.content,"questionOrder":_vm.currentQuestion.order,"content":_vm.currentQuestion.question.content,"options":_vm.currentQuestion.question.options,"correctAnswer":_vm.currentQuestion.question.answers ? _vm.currentQuestion.question.answers[0].answers[0] : '/',"isCorrect":true,"explanation":_vm.currentQuestion.question.exp,"showMark":false,"showLine":false}}):_vm._e()]:[(_vm.currentQuestion)?_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{staticClass:"border",attrs:{"span":12}},[_c('ViewPassage',{key:_vm.currentQuestion,attrs:{"passage":_vm.currentQuestion.question.passage,"type":_vm.currentQuestion.question_type,"section":_vm.currentQuestion.question.subject.name}})],1),_c('el-col',{staticClass:"border",attrs:{"span":12}},[_c('Question',{attrs:{"testType":_vm.currentQuestion.question_type,"question":_vm.currentQuestion.question,"disabledAction":true,"disableDelete":true,"displayCorrectAnswer":_vm.practice.show === 'CORRECTNESS_AND_EXPLANATION' ||
                    _vm.isRoleTeacher() ||
                    _vm.isRoleAdmin()}})],1)],1):_vm._e()]]:_vm._e()],2)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }